import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';

import EntityOsm from './pages/entity-osm';
import Header from './components/header';
import Home from './pages/home';

import packageInfo from '../package.json'

import config from './utils/config';

const VERSION = packageInfo.version;
const area = '(area);out body center qt;';

export default function App(){
    return (
	<Router>
	    <Header />
	    <h1>Gompass</h1>
	    <Routes>
		    <Route path='/' element={<Home/>}/>
		    <Route path='/bike-ride' element={<EntityOsm
							  address={config.ADDRESS + 'nwr[amenity=bicycle_parking][bike_ride]["bike_ride"!="no"]' + config.AREA }
							  title='B+R Stations'/>}/>
		    <Route path='/park-ride' element={<EntityOsm
							  address={ config.ADDRESS + 'nwr["park_ride"!="no"]["park_ride"]' + config.AREA }
							  title='P+R Stations'/>}/>
		    <Route path='/second-hand' element={<EntityOsm
						     address={ config.ADDRESS + 'nwr[shop=second_hand]' + config.AREA }
							  title='Second Hand Shops'/>}/>
		    <Route path='/taxi' element={<EntityOsm
						     address={ config.ADDRESS + 'nwr[amenity=taxi]' + config.AREA }
							  title='Taxi Stations'/>}/>
		    <Route path='/ticket-machine' element={<EntityOsm
							       address={ config.ADDRESS + 'nwr[amenity=vending_machine][vending=public_transport_tickets]' + config.AREA }
							       title='Ticket Machines'/>}/>
		    <Route path='/ticket-office' element={<EntityOsm
							      address={ config.ADDRESS + 'nwr[shop=ticket]["tickets:public_transport"!=no]' + config.AREA }
							      title='Ticket Offices'/>}/>
		</Routes>

		<p>
		    Version:&nbsp;{VERSION}
		</p>
		</Router>
    );
}
