import React from 'react'
import { Link} from 'react-router-dom';

export default function Home(){

    return (
	<>
	    <ul>
		<li>
	    <Link to={'/bike-ride'} >
		<button>
		    Bike and ride (B+R) stations
		</button>
	    </Link>
		</li>
		<li>
	    <Link to={'/park-ride'} >
		<button>
		    Park and ride (P+R) stations
		</button>
	    </Link>
		</li>
		<li>
	    <Link to={'/second-hand'} >
		<button>
		    Second hand shops
		</button>
	    </Link>
		</li>
		<li>
	    <Link to={'/taxi'} >
		<button>
		    Taxi stations
		</button>
	    </Link>
		</li>
		<li>
	    <Link to={'/ticket-machine'} >
		<button>
		    Ticket machines
		</button>
	    </Link>
		</li>
		<li>
	    <Link to={'/ticket-office'} >
		<button>
		    Ticket offices
		</button>
	    </Link>
		</li>
	    </ul>
	</>
    );
}
