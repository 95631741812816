import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import L from 'leaflet'

import "leaflet/dist/leaflet.css";

import { icon } from "../icons/icon-bfly";

//maxBounds={[[-60, -180], [85, 180]]}
const corner1 = L.latLng(-60, -180),
      corner2 = L.latLng(85, 180),
      bounds = L.latLngBounds(corner1, corner2);

export default function MapEntities( { entities } ) {
    //console.log('map-entities:MapEntities: entities.lngth: ' + entities.length);

    /*center map at Node: Umweltzentrum Braunschweig (3650315388)*/
    const mapCenter = [52.2671189, 10.5221905];

    const mapRef = useRef();

    useEffect(() => {

        const map = L.map( mapRef.current, {attributionControl: true})
	      .setView( mapCenter, 12).
	      setMaxBounds( bounds );

	L.tileLayer(
	    "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
	    {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		minZoom: 4,
		maxZoom: 18,
		scrollWheelZoom: false,
	    }).addTo(map);

	//TODO: Why is 'scrollWheelZoom: false,' above not working?
	//TODO: Why the following line instead?
	map.scrollWheelZoom.disable()

	entities.map( function( value, key ) {

	    //console.log('map-entities:MapEntities:map() value: ' +JSON.stringify(value));

	    L.Marker.prototype.options.icon = icon;

	    //get entity properties
	    const id = value.id;
	    const type = value.type;
	    const name = value.tags.name;
	    let lat, lon = null;
	    if ( type === "node"){
		lat = value.lat;
		lon = value.lon;
	    } else if ( type === "way" || type === "relation" ) {
		lat = value.center.lat;
		lon = value.center.lon;
	    } else {
		console.error("map-entities: OSM type (" + type + ") NOT known");
	    }

	    //verify entity properties
	    if(lat !== undefined && lat !== null && lon !== undefined && lon !== null ){
		var marker = L.marker([lat, lon]).addTo(map);
		const popupText ='name: <b>' + name + '</b><br />id: ' + id + '<br />type: ' + type;
		marker.bindPopup(popupText);
	    } else {
		console.error('map-entities: lat or lon undefined or null');
	    }
	});

	// unmount map function
	//You should unmount the function in react.js to remove the existing map.
	return () => map.remove();
    }, []);    

    if ( entities !== undefined && entities !== null && entities.length > 0 ) {
	return (
            <div
	    style={{padding: 0, margin: 0, height: "75vh",}}
            ref={el => mapRef.current = el}>
            </div>
	);
    }else{
	//TODO Why does mapRef have to be present?
	return (
            <>
		<div
            ref={el => mapRef.current = el}>
		</div>
		<p>MapEntities loading...</p>
            </>
	);
    }
};

MapEntities.propTypes = {
    entities: PropTypes.array
};
